<template>
  <div class="mt-0 main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        <router-link v-if="user[1] != 'DGIR'"
                     to="/Marche"> Marchés</router-link>
        <router-link v-else
                     to="/Marche"> Lettre de commande</router-link>
        <span> > Détails</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title"
               v-if="user[1] != 'DGIR'">
            Détails du marché
          </div>
          <div class="card-body text-center title"
               v-else>
            Détails de la lettre de commande
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row  py-2">
      <div class="col-md-10 mx-auto text-center">
        <button type="button"
                class="btn btn-f-blue mx-4"
                @click="goBack()">&nbsp; &#x21E6; Retour</button>
        <button v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')
                  ||(user[1] == 'AGEROUTE')||(user[1] == 'DGIR')"
                type="button"
                class="btn btn-f-blue"
                @click="launchUpdate(marche.id)">
          <i class="flaticon flaticon-pencil"></i>
          &nbsp; &nbsp;Modifier
        </button>
        <button v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                type="button"
                class="btn btn-f-blue mx-4"
                data-toggle="modal"
                data-target="#exampleModal">
          <i class="flaticon flaticon-delete"></i>&nbsp; &nbsp;Supprimer
        </button>
        <button v-if="user[1] == 'FER'"
                type="button"
                class="btn btn-f-blue"
                @click="showAvenants()">
          <i class="flaticon flaticon-files"></i>&nbsp; &nbsp;Avenants
        </button>
        <button v-if="user[1] == 'FER'"
                type="button"
                class="btn btn-f-blue mx-4"
                @click="showAccord()">
          <i class="flaticon flaticon-note"></i>&nbsp; &nbsp;Accords de financement
        </button>
      </div>
    </div>
    <!-- <div v-if="error.exist"
         class="row text-center mb-2">
      <div class="col-12">
        <h3 class=" text-uppercase d-content-red">{{ error.content.message }}</h3>
      </div>
    </div> -->
    <!-------------------------------------------- Theme 2 ------------------------------------------------>
    <div class="row mt-3">
      <table class="table col-md-10 mx-auto"
             style="font-size: 0.6em;">
        <thead>
          <tr class="bg-black">
            <th>Informations {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Numéro {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</td>
            <td>{{ marche.numero_marche }}</td>
          </tr>
          <tr>
            <td scope="row">Libellé</td>
            <td>{{ marche.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Objet</td>
            <td>{{ marche.objet }}</td>
          </tr>
          <tr>
            <td scope="row">Nature du reseau</td>
            <td>{{ marche.nature_reseau }}</td>
          </tr>
          <tr>
            <td scope="row">Lot</td>
            <td>{{ marche.lot }}</td>
          </tr>
          <tr>
            <td scope="row">Départements</td>
            <td>{{ marche.departement }}</td>
          </tr>
          <tr>
            <td scope="row">Type d'entretien</td>
            <td>{{ marche.type_entretien }}</td>
          </tr>
          <tr>
            <td scope="row">Date début</td>
            <td>{{ marche.date_debut }}</td>
          </tr>
          <tr>
            <td scope="row">Date fin</td>
            <td>{{ marche.date_fin }}</td>
          </tr>
          <tr>
            <td scope="row">Délai d'execution</td>
            <td>{{ marche.delai_execution_marche }} mois</td>
          </tr>
          <tr>
            <td scope="row">Domiciliation bancaire</td>
            <td v-if="marche.domiciliation != null">{{ marche.domiciliation }}</td>
            <td v-else></td>
          </tr>
          <tr>
            <td scope="row">Montant initial {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</td>
            <td v-if="!isNaN(Math.round(marche.montant_initial_marche))">{{ Math.round(marche.montant_initial_marche).toLocaleString() }} FCFA</td>
            <td v-else>0 FCFA</td>
          </tr>
          <tr>
            <td scope="row">Montant total des avenants</td>
            <td v-if="!isNaN(Math.round(total))">{{ Math.round(total).toLocaleString() }} FCFA</td>
            <td v-else>0 FCFA</td>
          </tr>
          <tr>
            <td scope="row">Montant total {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</td>
            <td>{{ Math.round(marche.montant_total).toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date du demarrage effectif</td>
            <td>{{ marche.date_demarrage_effectif }}</td>
          </tr>
          <tr>
            <td scope="row">Date d'etablissement du cautionnement ad</td>
            <td>{{ marche.date_etablissement_cautionnement_ad}}</td>
          </tr>
          <tr>
            <td scope="row">Date d'expiration du cautionnement ad</td>
            <td>{{ marche.expiration_caution_ad_date !="" && marche.expiration_caution_ad_date !=null?marche.expiration_caution_ad_date:marche.expiration_caution_ad_texte}}</td>
          </tr>
          <tr>
            <td scope="row">Montant de la caution d'avance de démarrage</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Date d'etablissement du cautionnement définitif</td>
            <td>{{ marche.date_etablissement_cautionnement_definitif}}</td>
          </tr>
          <tr>
            <td scope="row">Date d'expiration du cautionnement définitif</td>
            <td>{{ marche.date_expiration_caution_definitif_date !="" 
              && marche.expiration_caution_definitif_date !=null ? marche.expiration_caution_definitif_date : marche.expiration_caution_definitif_texte}}</td>
          </tr>
          <tr>
            <td scope="row">Montant du cautionnement définitif</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Date d'etablissement du cautionnement rg</td>
            <td>{{ marche.date_etablissement_cautionnement_rg}}</td>
          </tr>
          <tr>
            <td scope="row">Date d'expiration du cautionnement rg</td>
            <td>{{ marche.expiration_caution_rg_date !="" && marche.expiration_caution_rg_date!=null ?marche.expiration_caution_rg_date:marche.expiration_caution_rg_texte}}</td>
          </tr>
          <tr>
            <td scope="row">Montant de la  caution de retenue garantie</td>
            <td></td>
          </tr>
          <tr>
            <td scope="row">Date d'approbation {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</td>
            <td>{{ marche.date_approbation_marche}}</td>
          </tr>
          <tr>
            <td scope="row">Date de signature de l'autorité contractante</td>
            <td>{{ marche.date_signature_autorite_contractante}}</td>
          </tr>
          <tr>
            <td scope="row">Date de signature de l'attributaire</td>
            <td>{{ marche.date_sinature_attributaire}}</td>
          </tr>
          <!-- <tr>
            <td scope="row">Année de l’ordonnancement</td>
            <! -- <td>{{ marche.annee_ordonnancement}}</td> -- >
          </tr> -->
          <tr>
            <td scope="row">Date de fin des travaux</td>
            <td>{{ marche.date_fin_travaux}}</td>
          </tr>
          <tr>
            <td scope="row">Date de l'Ordre de service d'arrêt</td>
            <td>{{ marche.date_ordre_service_arret}}</td>
          </tr>
          <tr>
            <td scope="row">Date de reprise des travaux selon l'ordre de service</td>
            <td>{{ marche.date_reprise_travaux_selon_ordre_service}}</td>
          </tr>
          <tr>
            <td scope="row">Maitre d'ouvrage</td>
            <td>{{ marche.maitrise_ouvrage_deleguee}}</td>
          </tr>
          <tr>
            <td scope="row">Maitre d'ouvrage déléguée</td>
            <td>{{ marche.prestataire.libelle}}</td>
          </tr>
          <tr>
            <td scope="row">Mission de suivi de contrôle</td>
            <td>{{ marche.mission_suivi_controle}}</td>
          </tr>
          <tr>
            <td scope="row">Entreprise</td>
            <td>{{ marche.entreprise.libelle}}</td>
          </tr>
          <tr>
            <td scope="row">Reférence ANO</td>
            <td>{{ marche.rf_ano}}</td>
          </tr>
          <tr>
            <td scope="row">Reférences d'ordre de service de démarrage </td>
            <td>{{ marche.references_ordre_service_demarrage}}</td>
          </tr>
          <tr>
            <td scope="row">Références Ordre de service d'arrêt </td>
            <td>{{ marche.reference_ordre_service_arret}}</td>
          </tr>
          <tr>
            <td scope="row">Références Ordre de service de reprise des travaux </td>
            <td>{{ marche.reference_ordre_service_reprise_travaux}}</td>
          </tr>
          <tr>
            <td scope="row">Références Attestation de réservation de Crédit </td>
            <td>{{ marche.reference_arc}}</td>
          </tr>
          <tr>
            <td scope="row">Références Accord de financement </td>
            <td>{{ marche.reference_af}}</td>
          </tr>
          
          <!-- <tr>
            <td scope="row">Status</td>
            <td>
              <span v-if="marche.statut_validation == 'VALIDE'"
                    class="badge badge-pill badge-success">
                {{ marche.statut_validation }}
              </span>
              <span v-if="marche.statut_validation == 'NON_VALIDE'"
                    class="badge badge-pill badge-danger">
                {{ marche.statut_validation }}
              </span>
              <span v-if="marche.statut_validation == 'EN_ATTENTE'"
                    class="badge badge-pill badge-warning">
                {{ marche.statut_validation }}
              </span>
              <span v-if="marche.statut_validation == 'DEMANDE_INFORMATION'"
                    class="badge badge-pill badge-info">
                {{ marche.statut_validation }}
              </span>
            </td>
          </tr> -->
          <tr>
            <td scope="row">Description</td>
            <td>{{ marche.description }}</td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th>Source {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Programme</td>
            <td>{{ marche.activite.rubrique.programme.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Rubrique</td>
            <td>{{ marche.activite.rubrique.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Activité</td>
            <td>{{ marche.activite.libelle }}</td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th>Avenants {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(avenant, avenkey) in marche.avenants"
              :key="avenkey">
            <td scope="row">{{avenant.ref_avenant}} ({{avenant.nature_avenant}}) </td>
            <td class="text-right"
                v-if="avenant.nature_avenant!='CHANGEMENT_DOMICILIATION_BANCAIRE'">
              {{ Math.round(Number(avenant.montant)).toLocaleString() }} FCFA
            </td>
            <!-- <td class="text-right"
                v-else>
              {{ avenant.description }} FCFA
            </td> -->
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th>Ordonnancements {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ordonnancement, ordokey) in marche.ordonnancements"
              :key="ordokey">
            <td scope="row">Ordonnancement {{ordonnancement.annee_ordonnancement}}</td>
            <td class="text-right">
              {{ Math.round(Number(ordonnancement.montant_ordonnancement)).toLocaleString() }} FCFA
            </td>
            <!-- <td class="text-right"
                v-else>
              {{ avenant.description }} FCFA
            </td> -->
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th style="width:40%">Fichier à télécharger</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Référence de l'attestation de réservation de crédit</td>
            <td>
              <a :href="marche.reference_arc"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Cautionnement AD</td>
            <td>
              <a :href="marche.cautionnement_ad"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Cautionnement définitif</td>
            <td>
              <a :href="marche.cautionnement_definitif"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Cautionnement RG</td>
            <td>
              <a :href="marche.cautionnement_rg"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Page de garde {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</td>
            <td>
              <a :href="marche.page_garde_marche"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">R.I.B. de la lettre de commande</td>
            <td>
              <a :href="marche.lettre_commande_RIB"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Avis de non Objection (ANO)</td>
            <td>
              <a :href="marche.ordre_service_reprise_travaux"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Ordre de service d'arrêt</td>
            <td>
              <a :href="marche.ordre_service_arret"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Ordre de service de démarrage</td>
            <td>
              <a :href="marche.ordre_service_demarage"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Ordre de service de reprise des travaux</td>
            <td>
              <a :href="marche.ordre_service_reprise_travaux"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel">Supprimer un marché</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            Voulez-vous vraiment supprimer ce marché?
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    @click="supprimerMarche()">Oui</button>
            <button type="button"
                    class="btn btn-primary"
                    data-dismiss="modal">Non</button>
          </div>
          <!-- affichage avenant -->
          
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
export default {
  name: "MrchDetail",
  components: {
    Notif,
  },
  data: () => ({
    reference_arc: "",
    reference_af: "",
    user: "",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    total:"",
    error: {
      exist: false,
      content: ""
    }
  }),
  watch: {
    fileNames() {
      if (this.marche) {
        this.reference_arc = this.marche.reference_arc.split("/")
        this.reference_af = this.marche.reference_af.split("/")
      }
    },
    marche(){
      if (this.marche) {
        this.total=0
        this.marche.avenants.forEach(avenant => {
          this.total+=Number(avenant.montant)
        })
        this.marche.accords_financements.forEach(accord => {
          this.total+=Number(accord.montant)
        })
        
        var nouveau_departement=""
        for (let index = 0; index < this.marche.departement.length; index++) {
          if (index<this.marche.departement.length && index+1<this.marche.departement.length) {
            nouveau_departement+=this.marche.departement[index].libelle+" - "    
          }else{
            nouveau_departement+=this.marche.departement[index].libelle    
          }
        }
        this.marche.departement=nouveau_departement
        
      }
    },
    deleteMarches() {
      if (this.deleteMarches) {
        this.notif.message = this.deleteMarches
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.deleteMarche("")
            // location.reload()
            this.$refs["btn-close"].click()
            this.$router.push({ name: "Marche" })
          }.bind(this),
          3000
        )
      }
    },
    marcherrors() {
      if (this.marcherrors) {
        this.notif.message = this.marcherrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            // location.reload()
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        )
        this.setErors("")
      }
    },
    
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.getMarche(this.$route.params.id)
  },
  computed: {
    ...mapGetters(["marche", "deleteMarches", "marcherrors","listAvenants"])
  },
  methods: {
    ...mapActions(["getMarche", "delMarches","getListAvenants"]),
    ...mapMutations(["deleteMarche", "setErors","setListAvenants","setMarche"]),
    launchUpdate(id) {
      this.$router.push({ name: "Modification", params: { id: id } })
    },
    goBack() {
      this.$router.push({ name: "Marche" })
    },
    supprimerMarche() {
      this.delMarches(this.$route.params.id)
    },
    showAvenants(){
      this.$router.push({ name: "Avenants", params: { id: this.$route.params.id } })
    },
    showAccord(){
      this.$router.push({ name: "AccordsFinancement", params: { id: this.$route.params.id } })
    } 
  }
}
</script>
